@import 'vars';

.accordion {
  transition: background-color 300ms ease-in-out;
  border-radius: 8px;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and (max-width: $softphone-max-width) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &--inverse {
    .accordion__header__icon {
      width: 20px;
      height: 20px;
      background: transparent;
    }
  }

  &--inverse.accordion--open {
    background: $dark-grey;

    .accordion__header__icon {
      width: 30px;
      height: 30px;
      background: $white;
    }
  }

  &__title {
    margin: auto 0;
  }

  &--inverse > .accordion__header {
    .next-to > p {
      font-weight: $font-weight-bold;
      color: $white;
    }
  }

  &--open {
    background: $light-grey;
  }

  &__child {
    padding-bottom: 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    height: 60px;
    justify-content: space-between;

    &__icon {
      width: 35px;
      height: 35px;
      background: $background;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      @media only screen and (max-width: $softphone-max-width) {
        width: 25px;
        height: 25px;

         > svg {
           height: 15px !important;
           width: 15px !important;
           font-size: 15px !important;
         }
      }

      &--open {
        background: $white;
      }
    }

    &:hover &__icon {
      background: darken($background, 2%);
    }
  }
}
