@import 'vars';

.incoming-task-placement-icon {
  display: flex;
  align-items: center;
  height: 40px;

  &__window {
    background: $blue;
  }

  &--selected &__window {
    background: $white;
  }

  &__window {
    width: 50px;
    border-radius: 2px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 3px;

    &--top {
      align-items: flex-start;
    }
  }

  &__notification {
    width: 20px;
    border-radius: 1px;
    height: 7px;
    background: $white;

    &--selected {
      background: $blue;
    }
  }
}
