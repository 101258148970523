@import 'vars';

.customer-profile-form {
  &__block {
    margin-top: 40px;

    > h4 {
      margin-top: 0;
      margin-bottom: 30px;
    }

    &__row {
      display: flex;
      width: 100%;

      > *:first-child {
        margin-right: 60px;
      }

      > * {
        width: 50%;
        max-width: 400px;
      }
    }

    > .customer-profile-form {
      &__block {
        &__row:not(:last-of-type) {
          margin-bottom: 40px;
        }
      }
    }

    > .MuiFormControl-root {
      width: 100%;
      max-width: 800px;
    }
  }

  &__basic-info {
    display: flex;

    > *:first-child {
      margin-right: 60px;
    }

    .segmented-controls {
      max-width: 200px;
    }
  }

  &--compact &__block {
    margin-top: 40px;
  }

  &--compact &__block {
    > h4 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
}
