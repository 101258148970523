.interaction-history-table {
  .MuiTableRow-root:last-of-type {
    .MuiTableCell-root {
      border-bottom: 0;
    }
  }

  > thead {
    .MuiButtonBase-root {
      font-weight: 800 !important;
    }
  }
}
