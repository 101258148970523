@import 'vars';

@keyframes pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.grid-item {
  flex: 1;
  overflow: hidden;
  position: unset !important;
}

.count {
  display: flex;
  flex-direction: column;

  &--warning::after,
  &--alert::after {
    position: absolute;
    top: 0;
    width: 100%;
    height: 8px;
    content: "";
    left: 0;
    transition: opacity;
  }

  &--warning {
    &::after {
      background: $yellow;
    }
  }

  &--alert {
    &::after {
      background: $danger;
      animation: pulse 3s ease-in-out infinite;
    }
  }

  &__icon {
    position: absolute;
    bottom: 2px;
    left: 7px;
    font-size: 18px;
  }

  &__title {
    font-size: 18px;
    font-weight: 500 !important;
    height: 42px;
    margin-bottom: 0;

    &--small {
      font-size: 15px;
      height: 36px;
    }

    > svg {
      margin-left: 10px;
    }
  }

  &__number {
    align-self: center;
    justify-self: center;
    line-height: 50px;
    color: $dark-blue;
    font-size: 60px;
    margin: auto;
    font-weight: 600;

    &--small {
      font-size: 40px;
    }
  }
}
