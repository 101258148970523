@import 'vars';
@import 'mixins';

$left-right-padding: 30px;

.draggable-overlay {
  background-color: white;
  display: flex;
  position: fixed;
  padding-top: 20px;
  padding-bottom: 30px;
  left: 0;
  top: 0;
  width: $overlay-small;
  border-radius: 12px;
  flex-direction: column;
  cursor: default;
  box-shadow: rgb(0 0 0 / 5%) 0 6px 24px 0, rgb(0 0 0 / 8%) 0 0 0 1px;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  z-index: 2;
  max-height: 80vh;

  > hr {
    margin: 20px $left-right-padding;
    border: 0;
    border-top: 2px solid $light-grey;
  }

  &--hidden {
    opacity: 0;
    pointer-events: none;
  }

  &--large {
    width: $overlay-large;
  }

  &__header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: grab;
    padding-left: $left-right-padding;
    height: 25px;
    padding-right: $left-right-padding;

    &:active {
      cursor: grabbing;
    }

    &__title {
      display: flex;
      align-items: center;
      
      > h2 {
        margin-bottom: 0;
        padding: 0 5px;
      }

      &__loader {
        width: 0 !important;
        transition: width 0.2s ease-in-out;
        
        &--visible {
          width: 20px !important;
        }
      }
    }


  }

  &__content {
    @include scrollable-container;

    height: auto;
    padding-left: $left-right-padding;
    padding-right: $left-right-padding;

    &--large {
      max-height: 360px;
      overflow-y: scroll;
    }

    &--max-height {
      max-height: 410px;
      overflow-y: scroll;
    }
  }
}
