.interaction-history {
  height: 100%;

  &__date-selector {
    width: fit-content;
    margin-bottom: 40px;
  }

  &__table-footer {
    td {
      border-bottom: 0;
    }
  }
}
