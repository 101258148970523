@import 'vars';

.signing-out {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: $white;
  margin: auto;
  border-radius: 12px;
  padding: 30px;
  max-width: 700px;
  position: relative;

  > .loader-container {
    margin: 20px auto 0;
  }

  &__type-code {
    position: absolute;
    bottom: 30px;
    left: 30px;
    background: $light-grey;
    border-radius: 4px;
    padding: 3px 10px;
    font-weight: 500;
  }
}