@import 'vars';
@import 'mixins';

$modal-margin: 40px;

.softphone-modal {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $white;
  margin: #{$modal-margin} 0 0 #{$modal-margin};
  padding: 15px;

  // Max softphone (800) - 40px sidebar
  max-width: 760px;

  &--reversed {
    margin: #{$modal-margin} #{$modal-margin} 0 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: $white;

    &__children {
      @include scrollable-container;

      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;

      &__button:first-of-type {
        margin-right: 20px;
      }
    }
  }
}
