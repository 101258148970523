@import 'vars';

.info-panel-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  > p {
    margin-right: auto;
  }
}
