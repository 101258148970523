@import 'vars';

.intents {
  &__container {
    display: flex;
    margin-bottom: 10px;
    padding: 0 20px;
    flex-wrap: wrap;
  }

  &__content {
    background: $white;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 10px 5px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.transcript {
  &__container {
    margin-bottom: 12px;
    pointer-events: all;
    padding: 0 20px;

    .accordion {
      &__child {
        padding-bottom: unset;
        overflow: auto;
      }
    }
  }

  &__content {
    background: $white;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

.dynamic-attributes {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: $white;
    padding: 0 20px;
    margin-bottom: 10px;
  }

  &__label {
    margin-right: 5px;
    font-weight: $font-weight-bold;
  }

  &__value {
    &__link {
      cursor: pointer;
    }
  }
}
