@import 'vars';
@import 'mixins';

.softphone-queue {
  background: $background;
  border-radius: 6px;
  padding: 10px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &__container {
    &__details {
      display: flex;
      align-items: center;

      &__detail {
        display: flex;
        align-items: center;
        margin-top: 10px;

        &:not(:first-of-type) {
          margin-left: 20px;
        }

        > p {
          margin-left: 5px;
        }
      }
    }
  }
}