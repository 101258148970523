.header-task-banner {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;

  &__text {
    min-width: 0;
    margin-right: 20px;
    margin-left: 20px;

    &__connection {
      display: flex;
      align-items: baseline;
    
      > span {
        font-size: 12px;
      }
    
      > span:first-of-type {
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    
      > span:last-of-type {
        min-width: 50px;
        margin-left: 5px;
      }
    }

    > h2 {
      font-size: 15px;
      margin-bottom: 0;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__action {
    margin-left: auto;
    pointer-events: all;
    display: flex;
    align-items: center;
  }
}