@import 'vars';

.sidebar {
  &__parent-menu {
    padding: 0 10px !important;

    &__submenu-active {
      &__sidebar-closed {
        .nav-item {
          border-radius: 4px 4px 0 0;
          background-color: $blue !important;
          margin-bottom: unset !important;

          .base-icon {
            color: $white !important;
          }

          &:hover {
            border-radius: 4px 4px 0 0 !important;
          }
        }
      }
    }
  }

  &__closed {
    .nav-text {
      display: none;
    }
  }

  &__parent-menu:last-of-type {
    .nav-item {
      margin-bottom: unset;
    }
  }

  &__menu-items {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &__active {
      .nav-text > span {
        font-weight: $font-weight-bold;
      }
    }

    > .MuiListItemIcon-root {
      min-width: unset !important;
      margin-right: 15px;

      > svg {
        color: $dark-blue;
      }
    }
  }

  .nav-active {
    position: relative;
    background-color: $blue;
    border-radius: 4px;

    .base-icon {
      color: $white;
    }

    .nav-text span {
      color: $white !important;
      font-weight: $font-weight-bold !important;
    }

    &:hover {
      background-color: $blue;
    }
  }

  .nav-item {
    padding: 10px;
    margin-bottom: 20px;

    > .nav-text span {
      color: $text;
      font-size: 16px !important;
    }

    &:hover {
      color: $dark-blue;
      border-radius: 4px;
    }
  }

  .nav-icon-header {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 10px !important;
    margin-right: 3px !important;

    .MuiIconButton-label {
      color: $blue;
      font-size: 15px;
    }
  }

  .nav-text {
    margin: unset;
  }

  .nav-text > span {
    font-size: $font-size-normal;
    height: 20px;
  }

  &__collapse-active {
    margin-left: 29px;
    border-left: 2px solid $mid-grey;

    .sidebar {
      &__parent-menu {
        padding: 0 !important;
        padding-right: 10px !important;
        margin-left: 26px;
      }
    }

    &__sidebar-closed {
      padding: 0 10px;

      .MuiCollapse-wrapper {
        border: 2px solid $blue;
        border-radius: 0 0 4px 4px;
      }

      .sidebar {
        &__parent-menu {
          padding: 0 !important;
        }
      }

      .nav-item {
        padding: 8px;
      }

      .nav-item:first-of-type {
        margin-top: 10px;
      }

      .nav-item:last-of-type {
        margin-bottom: 10px;
      }

      .nav-active {
        background-color: unset;
        border-radius: unset;

        .base-icon {
          color: $blue;
        }

        &:hover {
          background-color: unset;
        }
      }
    }
  }
}
