.select__control {
  &__presence-states {
    min-width: 100px;

    .select {
      &__control {
        &__presence-states {
          &__option {
            &__indicator {
              display: none;
            }
          }
        }
      }
    }

    &__option {
      display: flex;
      align-items: center;

      &__indicator {
        display: block;
        width: 10px;
        height: 10px;
        min-width: 10px;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }
}
