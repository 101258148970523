@import 'vars';
@import 'mixins';

.popover-menu-item {
  display: flex;
  min-width: 210px !important;
  justify-content: space-between !important;
  padding: 8px 16px;

  @include softphone-only {
    min-width: 175px !important;
    height: 40px !important;
    min-height: 40px !important;
  }

  &--disabled {
    opacity: 0.5;
    cursor: default !important;

    &:hover {
      background: unset !important;
    }
  }

  &--no-interaction {
    pointer-events: none;
  }

  > * {
    font-size: $font-size-normal;

    @include softphone-only {
      font-size: $font-size-small;
    }
  }

  &__icon {
    display: flex;
    margin-left: 20px;
  }
}