@import 'vars';

.header-tasks {
  transition: all ease-in-out 0.5s;
  background: $white;
  border: 1px solid $mid-grey;
  min-width: 300px;
  max-width: 400px;
  width: auto;
  margin-left: 70px;
  border-radius: 4px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;

  &--small {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
  }

  &__button {
    width: 100%;
    background: none;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    > p {
      margin-left: 10px;
      margin-right: auto;
      font-weight: bold;
    }
  }

  &__task {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    width: 270px;

    &:first-of-type {
      margin-top: 10px;
    }

    &:not(:last-of-type) {
      padding-bottom: 15px;
      border-bottom: 1px solid $mid-grey;
    }
  }
}