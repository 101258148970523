@import 'vars';

.customer-profile {
  &__error {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;

    &__text {
      > p {
        margin-bottom: 0;
      }
    }

    > svg {
      max-height: 200px;
      width: auto;
    }
  }

  &__history {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    .MuiTableContainer-root {
      max-height: unset !important;
    }
  }

  &__content {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 30px;

      &__back {
        display: flex;
        align-items: center;
        color: $text !important;

        &:hover {
          text-decoration: underline;
        }

        > h2 {
          margin: 0;
        }

        > svg {
          margin-right: 10px;
        }
      }
    }

    &__profile {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 30px;

      @media only screen and (max-width: 1100px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__info {
        display: flex;
        flex-direction: column;
      }

      &__summary {
        display: flex;
        margin-right: 70px;
        padding-right: 70px;
        border-right: 1px solid $mid-grey;
        height: 150px;
        align-items: center;

        @media only screen and (max-width: 1100px) {
          width: 100%;
          border-right: 0;
          border-bottom: 1px solid $mid-grey;
          padding-right: 0;
          margin-right: 0;
          margin-bottom: 40px;
          padding-bottom: 40px;
          height: unset;
        }

        > .avatar {
          margin-right: 30px;
        }

        > h1 {
          margin: 0;
        }

        &__dot {
          display: block;
          margin-left: 15px;
          height: 15px;
          min-width: 15px;
          width: 15px;
          border-radius: 50%;
          margin-top: 5px;
        }

        &__flag {
          margin-left: 15px;
          font-size: 15px;
          color: $danger;
        }
      }
    }
  }
}
