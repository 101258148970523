@import 'vars';
@import 'mixins';

.customer-profile {
  @include scrollable-container;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    top: 0;
    background: $white;
    flex-wrap: wrap;
    gap: 20px;
    z-index: 1;
    position: sticky;

    &__username {
      display: flex;
      align-items: center;
      width: fit-content;
      max-width: 100%;

      > h2 {
        margin-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__not-found {
    display: flex;
    justify-content: space-between;

    &:not(&--small) > button {
      margin-left: 20px;
    }

    &--small {
      flex-direction: column;

      > button {
        margin-top: 30px;
      }
    }

    &__info {
      > h2 {
        margin: unset;
        margin-bottom: 5px;
      }
    }

    &__image {
      margin-top: auto;
      margin-bottom: auto;
      max-width: 300px;
      align-self: center;
    }
  }

  hr {
    margin: 32px 0;
    border: 0;
    border-top: 2px solid $light-grey;
  }

  .profile-notes {
    flex-direction: column;
    min-height: unset;
    z-index: 0 !important;
  }

  .profile-info {
    padding-bottom: 20px;
  }

  .content-panel {
    &__header {
      margin-bottom: 20px;
    }
  }
}