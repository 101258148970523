@import "vars";
@import 'mixins';

.fusion-data {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 40px;

  @include softphone-only {
    column-gap: 20px;
  }

  &__link {
    cursor: pointer;

    > svg {
      margin-right: 5px;
    }

    > span {
      text-decoration: underline;
    }
  }

  &__block {
    &__badges {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__accordion {
    @include softphone-only {
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
    }

    &__child {
      &--nested {
        padding-bottom: 0;
      }
    }
  }

  &__label {
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 10px;
  }

  &__accordion-line {
    min-height: 24px;
    width: 100%;
    padding: 5px 10px;
    background-color: $white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}