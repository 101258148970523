.pie-graph {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__chart {
    height: calc(100% - 40px);
  }

  &__title {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 500 !important;
  }
}
