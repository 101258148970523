@import 'vars';

.edit-widget {
  min-width: 500px;

  &__title {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 20px;

    > button {
      padding: 0;
      border: 0;
      background: 0;
      margin-left: auto;

      > svg {
        color: $danger !important;
      }
    }
  }

  &-item {
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }

    &__threshold-row {
      > *:last-child {
        width: 100px;
      }
    }

    &__date-range-row {
      > *:first-child {
        width: 100px;
      }
    }
  }
}

.edit-widget .MuiPaper-root {
  width: 250px;
  padding: 20px;
}

.edit-widget .MuiPaper-root .MuiSelect-selectMenu {
  font-size: 12px;
}

.edit-widget-item {
  margin-bottom: 30px;
  margin-left: 5px;
}

.edit-widget-label {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.edit-widget-item .github-picker {
  background-color: #000 !important;
  width: 100%;
  padding-left: 5px;
}

.edit-widget-button-array {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  > button:first-of-type {
    margin-right: 10px;
  }
}

.widget-menu-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  background: $white;
}

.edit-widget-item input {
  text-align: center;
}

.inline-selector .MuiSelect-select {
  font-size: 10px;
}

.edit-widget-item .Mui-disabled {
  opacity: 0.5;
}
