@import 'vars';

.choose-layout-modal {
  &__options {
    display: flex;
    align-items: center;

    > *:first-child {
      margin-right: 30px;
    }

    &__option {
      background: none;
      border: none;

      > .layout-columns-icon {
        border-radius: 6px;
        border: 3px solid $mid-grey;
        cursor: pointer;
        padding: 15px;
        box-sizing: content-box;

        &:hover {
          border-color: $blue;
        }

        &--selected {
          border-color: $blue;
          cursor: default;
        }
      }
    }
  }
}