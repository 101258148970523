@import 'vars';

.tasks-filter-menu {
  position: relative;
  cursor: pointer;

  &__icon {
    font-size: 20px;
    margin-right: 3px;
  }

  &__dot {
    background-color: $blue;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    position: absolute;
    bottom: 4px;
    right: 0;
  }

  &__body {
    width: 288px;
    margin: 12px 20px;

    &__header {
      margin-bottom: 7px;
    }
  }
}