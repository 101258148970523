@import 'vars';

.preferences {
  overflow: visible;

  &__play-icon {
    justify-content: center !important;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    background: $white;
    padding-top: 20px;
    width: calc(100% + 20px);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -10px;
  }

  &__phone-number {
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      &__input {
        width: 100%;
        margin-right: 10px;
      }
    }
  }

  &__warning-text {
    margin-top: 5px !important;
    color: $warning;
    font-size: $font-size-small;
    margin-top: 10px !important;
  }

  &__ringtone {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > *:first-child {
      &:not(:last-child) {
        margin-right: 20px;
      }

      width: 100%;
    }

    > *:first-child:not(:last-child) {
      margin-right: 15px;
    }

    > *:last-child:not(:first-child) {
      margin-right: 5px;
    }
  }

  .react-select {
    &__control {
      height: 45px;
    }
  }

  .flag-dropdown {
    background-color: white !important;
  }
}
