@import 'mixins';

.softphone-create-agent-task {
  flex: 1;
  display: flex;
  flex-direction: column;

  @include large-softphone-only {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    > button {
      margin-left: 10px;
    }
  }
}