@import 'vars';

.task-status-badge {
  background: $blue;
  color: white;
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  height: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: fit-content;

  &--on-hold {
    background: $warning;
  }


  &--neglected {
    background: $danger;
  }
}