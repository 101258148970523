.contact-not-found {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: auto;

  > svg {
    margin-right: 40px;
    max-width: 300px;
    max-height: 240px;
  }

  &__info {
    min-width: 400px;

    &__actions {
      display: flex;
      align-items: center;
      margin-top: 30px;

      > *:first-child {
        margin-right: 20px;
      }
    }
  }
}
