@import 'mixins';

.softphone-only {
  padding: 0 !important;
  max-width: $softphone-max-width;

  &__background {
    background: $light-grey;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
  }

  // Override styles for when we're in softphone-only mode and the screen is larger than softphone-max-width //
  // Elements will reset back to desktop size and in some use cases this visually breaks the softphone //
  // !! This is only for generic components that break softphone when at desktop size !! //
  @include large-softphone-only {
    .accordion {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}