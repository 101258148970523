@import 'vars';

.task-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  flex: 1;
  padding: 0px 20px;

  &--placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0px 20px;

    > svg {
      max-width: 300px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 0;
    position: sticky;
    top: 0;
    background: $white;
    flex-wrap: wrap;
    gap: 20px;
  }
}
