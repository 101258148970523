.select-search {
  color: white;
  position: fixed;
  background-color: #000;
  z-index: 1;
  font-size: 10px;
}

.select-search svg {
  color: white;
}

.select-search input {
  color: white;
  font-size: 12px;
}

.select-search .MuiInput-underline::after {
  border: 0;
}

.pop-select-list {
  margin-top: 30px;
}

.pop-select-item {
  text-align: left !important;
  width: 100%;
}

.pop-select-item .MuiButton-label {
  color: white;
  text-align: left !important;
  font-size: 10px;
  width: 100%;
  justify-content: flex-start;
}
