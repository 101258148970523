.softphone-interaction-history-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  height: 40px;
  min-height: 40px;

  &__page {
    display: flex;
    align-items: center;

    > p {
      margin-right: 10px;
    }

    &__options {
      min-width: 85px;
    }
  }

  &__options-popover {
    max-width: 300px;
    padding: 15px;

    &__date-selector {
      > div {
        flex-direction: column;

        > * {
          padding-left: 0;
          padding-right: 0;

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}