@import 'vars';

.task-footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid $mid-grey;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: white;
  margin-top: auto;

  &__row {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}