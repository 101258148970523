@import 'vars';

/**
 * The CSS in this file is for styling the demo page,
 * Meny's critical styles (such as transforms) are applied
 * via JavaScript.
 *
 * See the documentation here: https://github.com/hakimel/meny#meny
 *
 * @author Hakim El Hattab | http://hakim.se
 */

* {
  margin: 0;
  padding: 0;
}

a {
  color: $primary;
  text-decoration: none;
  transition: 0.15s color ease;
}

a:hover {
  color: $primary;
}

h1,
h2 {
  font-size: 24px;
}

.meny {
  display: none;
  padding: 20px;
  overflow: auto;
  background: #333;
  color: #eee;
  box-sizing: border-box;
  box-sizing: border-box;
  box-sizing: border-box;
}

.meny ul {
  margin-top: 10px;
}

.meny ul li {
  display: inline-block;
  width: 200px;
  list-style: none;
  font-size: 20px;
  padding: 3px 10px;
}

.meny ul li::before {
  content: "-";
  margin-right: 5px;
  color: rgb(255 255 255 / 20%);
}

/**
 * Hint graphic that appears while menu is inactive
 */
.meny-arrow {
  position: absolute;
  z-index: 10;
  border: 10px solid transparent;
  transition: opacity 0.4s ease 0.4s;
}

.meny-left .meny-arrow {
  left: 14px;
  top: 50%;
  margin-top: -16px;
  border-left: 16px solid #333;
}

.meny-right .meny-arrow {
  right: 14px;
  top: 50%;
  margin-top: -16px;
  border-right: 16px solid #333;
}

.meny-top .meny-arrow {
  left: 50%;
  top: 14px;
  margin-left: -16px;
  border-top: 16px solid #333;
}

.meny-bottom .meny-arrow {
  left: 50%;
  bottom: 14px;
  margin-left: -16px;
  border-bottom: 16px solid #333;
}

.meny-active .meny-arrow {
  opacity: 0;
  transition: opacity 0.2s ease;
}

/**
 * Main contents area
 */
.contents {
  background: #eee;
  padding: 20px 40px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform-style: preserve-3d;
  box-sizing: border-box;
}

.contents > article {
  max-width: 400px;
}

.contents p {
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.32;
}

.contents small {
  display: block;
  margin-top: 10px;
  padding-top: 10px;
  color: #333;
  font-size: 0.85em;
  border-top: 1px dashed #ccc;
  text-size-adjust: none;
}

.contents .sharing {
  position: absolute;
  bottom: 20px;
}

.MuiListItemIcon-root {
  min-width: 40px !important;
}
