@import "vars";
@import 'mixins';

.softphone-header {
  height: 40px;
  display: flex;
  background: $white;
  border-bottom: 2px solid $background;
  width: 100%;
  padding: 0 15px 0 10px;
  align-items: center;

  &--reversed {
    padding: 0 10px 0 15px;
  }

  &__icon {
    height: 20px;
    margin-right: 10px;
  }
  
  &__title {
    margin-left: auto;
    display: flex;
    align-items: center;

    &__text {
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  &__close-button {
    margin-left: 15px;
  }

  &__agent-status-timer {
    margin-left: auto;
  }
}
