.module-error {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  flex: 1;
  justify-content: center;

  &--small {
    align-items: start;
    text-align: left;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-align: left;
  }
}
