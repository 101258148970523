@import 'vars';

.widget {
  iframe {
    min-height: 30vh;
    border-radius: 12px;
  }

  &__iframe-container {
    margin-top: 20px;
    display: flex;
  }

  &:not(:last-of-type) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid $light-grey;
  }
}
