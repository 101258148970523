@import 'vars';

.dialpad {
  &__container {
    text-align: center;
    height: 230px;
  }

  &__number-input {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > .phone-input {
      width: 100%;
    }
  }

  &__row {
    &__digit {
      display: flex;
      width: 30px;
      font-size: $font-size-large;
      font-weight: $font-weight-medium;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: $light-grey;
        border-radius: 4px;
      }

      > .sub {
        font-size: $font-size-extra-small;
        font-weight: $font-weight-book;
      }
    }
  }

  .react-tel-input .form-control {
    height: 42px;
    width: 100% !important;
  }

  &__actions {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    margin: 20px auto auto;
    width: 300px;

    &--small {
      width: 255px;
    }

    &__phone {
      width: 100px;
    }

    &--small &__phone {
      width: 85px;
    }

    &__transfer {
      margin-left: 40px;
      display: flex;
      justify-content: center;
    }

    &--small &__transfer {
      margin-left: 80px;
    }

    &__backspace {
      width: 100px;
      display: flex;
      justify-content: center;
    }

    &--small &__backspace {
      width: 85px;
    }
  }
}