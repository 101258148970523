@import 'vars';

.customer-profiles-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  border: 1px solid transparent;
  height: 45px;
  width: 100%;
  max-width: 800px;
  border-radius: 6px;
  margin-right: auto;

  &--disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &:focus-within {
    border-color: $blue;

    > svg {
      color: $blue;
    }
  }

  > svg {
    font-size: 18px;
    color: $mid-grey;
  }

  &__dropdown {
    max-height: 42px;
    min-width: 170px;
    margin-left: 20px;

    > div:first-of-type {
      border: 0;
      box-shadow: none;
      min-height: unset !important;
    }
  }

  > input {
    margin-left: 20px;
    flex: 1;
    height: 100%;
    border: 0;
    font-size: 16px;

    &::placeholder {
      color: $mid-grey;
      font-size: 14px;
    }
  }
}
