@import 'vars';
@import 'mixins';

.softphone-interaction-history {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__loader {
    margin: auto;
  }

  &__item {
    background: $background;
    border-radius: 6px;
    padding: 15px;

    @include small-softphone-only {
      padding: 10px;
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &__entry {
      display: flex;
      align-items: center;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      &__date {
        white-space: nowrap;
        margin-left: 10px;
        width: 100px;
        text-align: right;

        @include small-softphone-only {
          font-size: 12px;
        }
      }
    }

    &--single-row {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &--single-row &__title {
      margin-bottom: 0;
    }
  }
}