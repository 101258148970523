@import 'vars';

.layout-columns-icon {
  display: flex;
  align-items: center;
  height: 30px;

  &__column {
    background: $blue;
  }

  &--selected &__column {
    background: $white;

    .base-icon {
      color: $blue;
    }
  }

  &__column {
    width: 13px;
    border-radius: 2px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--double-width {
      // 13px + 13px + 3px margin
      width: 29px;
    }

    &:not(:last-of-type) {
      margin-right: 3px;
    }
  }

  &__split-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 3px;

    > .layout-columns-icon__column {
      height: 50%;
    }
  }

  &--large {
    height: 100px;
  }

  &--large &__column {
    width: 40px;
    border-radius: 6px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    &--double-width {
      // 40px + 40px + 10px margin
      width: 90px;
    }
  }

  &--large  &__split-column {
    gap: 10px;
  }

  &--inverse &__column {
    background: $mid-grey;
  }

  &--inverse.layout-columns-icon--selected &__column {
    background: $blue;

    .base-icon {
      color: $white;
    }
  }
}