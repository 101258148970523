.actions {
  .base-icon {
    width: unset !important;
  }

  > * {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}