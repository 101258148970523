@import 'mixins';

.error {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  padding: 15%;
  background: white;
  z-index: 1000;

  &--relative {
    position: unset;
    width: unset;
    height: unset;
    padding: unset;
    z-index: unset;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__page {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 2000px;
    margin: auto;
    text-align: left;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1000px) {
      flex-direction: column-reverse;
      text-align: center;
    }

    > svg {
      width: 100%;
      max-width: 450px;
      height: auto;
      flex: 1;
      z-index: 2;
      position: relative;
      margin-left: 40px;

      @media only screen and (max-width: 1000px) {
        flex: unset;
        max-width: unset;
        max-height: 400px;
        margin-left: 0;
      }

      @include softphone-only {
        max-width: 300px;
      }

      @include small-softphone-only {
        max-width: 80%;
      }
    }
  }

  &__content {
    max-width: 600px;
    flex: 1;
    margin-right: 2%;
    z-index: 2;
    position: relative;
    min-width: 50%;

    &__text {
      white-space: pre-wrap;
    }

    @media only screen and (max-width: 1000px) {
      margin-right: 0;
      margin-top: 40px;
      flex: unset;
    }

    > h2 {
      @include softphone-only {
        margin-top: 10px;
        font-weight: 400;
      }
    }

    > .button {
      margin-top: 40px;
      min-width: 150px;
    }
  }
}
