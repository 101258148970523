@import 'vars';

@mixin popup {
  border: 0 !important;
  box-shadow: rgb(0 0 0 / 5%) 0 6px 24px 0, rgb(0 0 0 / 8%) 0 0 0 1px !important;
  background: white;
}

@mixin tf-x {
  left: 50%;
  transform: translateX(-50%);
}

@mixin tf-y {
  top: 50%;
  transform: translateY(-50%);
}

@mixin tf-xy {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin textCenter {
  position: absolute;
  float: left;
  margin: 0;
  padding: 0;
  width: 80%;
  text-align: center;

  @include tf-xy;
  @include ellipsis;
}

@mixin hor-splitter {
  position: relative;
  float: left;
  width: 90%;
  height: 1px;
  background: rgb(0 0 0 / 10%);
  margin-top: 1vw;
  margin-bottom: 1vw;

  @include tf-x;
}

@mixin hor-splitter-no-margin {
  position: relative;
  float: left;
  width: 90%;
  height: 1px;
  background: rgb(0 0 0 / 10%);

  @include tf-x;
}

@mixin ellipsis {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

@mixin wrap-text-bulletproof {
  word-break: break-all;
}

@mixin transitionColor {
  transition: color 0.1s ease-in-out;
}

@mixin transitionBackground {
  transition: background 0.1s ease-in-out;
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin box-shadow($x, $y, $z, $i, $color) {
  box-shadow: $x $y $z $i $color;
}

@mixin scrollable-container {
  overflow-y: auto;

  &::-webkit-scrollbar:vertical {
    width: 8px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $mid-grey;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $dark-grey;
  }
}

// -- Break points -- //

@mixin softphone-only {
  @media (max-width: $softphone-max-width) {
    @content;
  }
}

@mixin small-softphone-only {
  @media (max-width: $softphone-small-width) {
    @content;
  }
}

// This is used when softphone-only is enabled and we grow past softphone max width
@mixin large-softphone-only {
  @media (min-width: calc(#{$softphone-max-width} + 1px)) {
    @content;
  }
}
