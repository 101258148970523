@import 'vars';

.action-menu {
  display: flex;

  &__trigger {
    font-size: 20px;
    cursor: pointer;
  }

  &__menu {
    .MuiPaper-root {
      margin-top: 10px;
    }

    .MuiSelect-root {
      margin-right: 0;
    }

    .MuiButtonBase-root {
      display: flex;
      justify-content: space-between;

      &:focus {
        background-color: $blue !important;
      }
    }

    .MuiListItemText-root {
      flex: unset;
    }

    .MuiListItemIcon-root {
      min-width: 20px !important;
      margin-left: 20px;
    }
  }
}
