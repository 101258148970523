@import 'vars';

.dashboards {
  transition: margin 0.2s ease-in-out;
  z-index: 1;
  position: relative;

  &__actions__action {
    margin-left: 20px;
  }

  > .component-title:not(.dashboards__error-title) {
    margin-bottom: 0;
  }

  .dashboard-select {
    margin-right: 20px;
  }

  .update-time {
    margin-bottom: 20px;
    min-height: 20px;
    font-size: 14px;
    margin-top: 10px;
  }

  .widget-module {
    padding: 15px;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    transition: background-color 0.2s ease-in-out;
    height: 100%;
    width: 100%;
  }
}
