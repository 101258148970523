@import 'vars';
@import 'mixins';

.chat-message {
  max-width: 80%;
  width: fit-content;
  margin-bottom: 5px;
  border-radius: 8px;
  padding: 10px 15px;
  background: $background;
  font-size: $font-size-small;
  word-break: break-word;

  &--small {
    max-width: calc(100% - 10px);
  }

  > .chat-message-loader {
    margin-left: 5px;
    margin-right: 5px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  // Increase the margin between messages not sent by the same person
  &:not(&--agent) + .chat-message--agent,
  &--agent + .chat-message:not(.chat-message--agent) {
    margin-top: 20px;
  }

  &--agent {
    margin-left: auto;
    text-align: right;
    background: $pastel-blue;
  }

  &--customer {
    &--invert {
      background-color: $background;

      @include softphone-only {
        background-color: $white;
      }
    }
  }

  &--customer.chat-message--end {
    border-radius: 8px 8px 8px 0;
  }

  &--agent.chat-message--end {
    border-radius: 8px 8px 0 8px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;

    &__time {
      display: flex;
      align-items: center;
      white-space: nowrap;

      > p {
        margin-left: 5px;
        font-size: $font-size-extra-small;
      }
    }

    > p {
      font-size: $font-size-extra-small;

      &:first-of-type {
        font-weight: 800;
        margin-right: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.chat-message-loader {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $blue;
  color: $blue;
  animation: flash 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.chat-message-loader::before,
.chat-message-loader::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.chat-message-loader::before {
  left: -12px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $blue;
  color: $blue;
  animation: flash 1s infinite alternate;
  animation-delay: 0s;
}

.chat-message-loader::after {
  left: 12px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $blue;
  color: $blue;
  animation: flash 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes flash {
  0% {
    background-color: $blue;
  }
  50%,
  100% {
    background-color: lighten($blue, 35%);
  }
}
