@import 'vars';

.segmented-controls {
  display: flex;
  background: $white;
  width: 100%;

  &--square {
    width: fit-content;
  }

  &__option {
    padding: 5px 15px;
    flex: 1;
    background: none;
    border: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:not(:last-of-type) {
      border-right: 2px solid $blue;
    }

    &:not(&--selected) {
      cursor: pointer;
      border: 2px solid $blue;

      &:hover {
        background: $light-grey;
      }
    }

    &--selected {
      background: $blue;
      padding: 5px 13px;
      pointer-events: none;
      color: white;
      font-weight: 600;
    }
  }

  &--square &__option {
    height: 75px;
    width: 75px;
    min-width: 75px;
  }
}
