@import 'mixins';

.no-task-view {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  &--small {
    flex-direction: column;

    > .no-tasks-placeholder {
      margin-right: 0;
      margin-bottom: 40px;
      min-height: 0;
    }
  }

  &--small &__column {
    width: 100%;
    height: 50%;

    &--minimised {
      position: fixed !important;
      top: 95px;
      right: 40px;
    }

    &__content {
      overflow: auto;
      height: 100%;

      &__header {
        background: white;
        position: sticky;
        top: 0;
      }
    }
  }

  > .no-tasks-placeholder {
    margin-right: 40px;
  }

  &__column {    
    width: 50%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    margin-bottom: auto;
    padding: 30px 30px 10px;

    &--minimised {
      width: 60px !important;
      min-width: 60px !important;
      height: 60px !important;
      min-height: 60px !important;
      border-radius: 50% !important;
      padding: 0 !important;
      overflow: visible;
    }

    &--minimised &__content {
      height: 100%;
      cursor: pointer;
    }

    &--minimised &__content {
      border-radius: 50%;
      height: 60px;
      min-height: 60px;
      position: unset;
      overflow: hidden;

      &__header {
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        > h2 {
          height: 100%;
          width: 100%;

          > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    &__add-agent-task-button {
      pointer-events: none;
      margin-top: 20px;
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      height: 0;

      &-enter {
        opacity: 0;
      }

      &-enter-done {
        opacity: 1;
        pointer-events: all;
      }

      &-exit-done {
        opacity: 0;
      }
    }

    &__content {
      @include scrollable-container;
      border: 0;
      background: none;
      padding: 0;

      > .MuiDivider-root {
        margin-left: 0;
        margin-right: 0;
      }

      &__header {
        margin-bottom: 40px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        position: sticky;
        top: 0;
        background: white;

        > h2 {
          text-align: left;
          margin-right: 10px;
        }

        &__maximise {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &__row {
        &__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 30px;
          text-align: left;

          &:not(:last-of-type) {
            margin-bottom: 30px;
          }

          &__text {
            user-select: all;
            cursor: text;
          }

          &__header {
            margin-bottom: 10px;
          }

          &__button {
            width: 215px;
            min-width: 215px;
            margin-left: 30px;

            > .MuiButtonBase-root {
              position: unset;
            }
          }

          @media only screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;

            &__button {
              margin-top: 30px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
