@import "vars";

.badge {
  border-radius: 6px;
  color: white;
  font-size: 12px;
  padding: 5px 10px;
  display: inline-flex;
  min-width: 40px;
  justify-content: center;

  &__success {
    background-color: $success;
  }

  &__error {
    background-color: $danger;
  }

  &__neutral {
    background-color: $background;
    color: $dark-blue;

    &--invert {
      background-color: $white;
    }
  }
}
