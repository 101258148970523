@import 'mixins';

// Use an offset to account for components like dropdown where the focus state gets cut off
// Total padding should be 30px
$padding-offset: 2px;
$modal-padding: 28px;

.cw-modal {
  display: flex;
  margin: 15px;

  &__children {
    background: white;
    max-width: 700px;
    border-radius: 12px;
    padding: $modal-padding;
    margin: auto;
    max-height: calc(100vh - 40px);
    width: 100%;
    display: flex;
    flex-direction: column;

    &__content {
      @include scrollable-container;
      
      padding: $padding-offset;

      &--padded {
        padding-top: 5px;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;

      > *:first-child:not(:last-child) {
        margin-right: 20px;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      padding: $padding-offset $padding-offset 0 $padding-offset;

      > h2 {
        font-size: 1.5rem;
        margin-bottom: 0;
      }

      &__close {
        margin-left: auto;
      }
    }

    &__content {
      flex: 1;
    }
  }
}
