@import 'vars';
@import 'mixins';

.timed-task {
  @include scrollable-container;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;

  &__time {
    margin-top: 30px;
  }

  > .user-information--row:first-of-type {
    margin-bottom: 20px;
  }

   > svg {
     font-size: 40px;
   }

   > h2 {
     margin-top: 20px;
     margin-bottom: 10px;
   }
 }