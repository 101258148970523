@import 'vars';
@import 'mixins';

.softphone-task-info-panel {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background: $background;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;

    &__section {
      display: flex;
      align-items: center;

      > *:first-child {
        margin-right: 5px;
      }

      @include small-softphone-only {
        > p {
          font-size: 12px;
        }

        > svg {
          width: 12px !important;
          height: 12px !important;
        }
      }
    }
  }

  &__content {
    border-left: 2px solid $background;
    border-right: 2px solid $background;
    border-bottom: 2px solid $background;
    padding: 5px 10px;
  }
}
