@import 'vars';
@import 'mixins';

.queue {
  &:not(:last-of-type) {
    border-bottom: 2px solid $background;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    justify-content: space-between;

    &__queue-name {
      @include small-softphone-only {
        font-size: 12px;
        display: flex;
        align-items: center;
      }
    }

    &__details {
      display: flex;

      > p {
        width: 65px;
        text-align: center;
        font-weight: $font-weight-bold;
      }
    }
  }

  > hr {
    margin: 10px 0;
    border: 0;
    border-top: 2px solid $light-grey;
  }
}