@import 'vars';

.metrics-config {
  &__header {
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;

    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  &__interval {
    display: flex;
    max-width: 300px;
  }

  &__add-group {
    margin-left: auto !important;
  }
}
