.customer-profiles-placeholder {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: auto;

  > svg {
    margin-right: 40px;
    max-width: 350px;
    max-height: 300px;
  }

  > h2 {
    margin-bottom: 0;
  }
}
