@import 'vars';

.status-icon {
  color: $blue;

  &--on-hold {
    color: $warning;
  }

  &--neglected {
    color: $danger;
  }
}