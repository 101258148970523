@import 'vars';

.user-information {
  display: flex;

  &:not(&--row) > span {
    margin-right: 10px;
  }

  > svg {
    min-width: 20px;
  }

  > p {
    margin: 0;
    width: 50px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &--row {
    flex-direction: row;
    width: 100%;
    align-items: center;
    max-width: 400px;

    > *:not(:last-child) {
      margin-right: 20px;
    }

    > span {
      margin-left: auto;
    }

    > h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      text-align: left;
    }
  }

  &__action-button {
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
}
