@import 'vars';

.empty-placeholder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;

  > svg {
    margin-right: 40px;
    max-width: 350px;
    max-height: 300px;
  }

  &__message {
    margin-bottom: 0;
    max-width: 600px;
  }

  &--small {
    flex-direction: column;
    text-align: center;

    > svg {
      margin-right: 0;
      margin-bottom: 40px;
      max-width: 250px;
    }
  }

  &--small &__message {
    max-width: 300px;
  }
}
