@import 'vars';

.avatar {
  background: $light-grey;
  color: $dark-grey;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  position: relative;

  &__initials {
    color: $text;
    letter-spacing: 1px;
  }

  &__status-dot {
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid white;
  }
}
