@import 'vars';
@import 'mixins';
@import 'softphone-only';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;

  .page-title {
    font-size: 1.75em;
    margin-top: 3%;
    margin-left: 30%;
  }

  .page-title2 {
    margin-top: 2%;
    font-family: $font;
    font-size: 24px;
    font-weight: $font-weight-medium;
    margin-left: 5px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    text-align: center;
  }
}

body {
  background: $white;
}

.body {
  &--dragging {
    cursor: ew-resize;

    * {
      user-select: none;
    }
  }

  &--dragging-vertical {
    cursor: ns-resize;

    * {
      user-select: none;
    }
  }
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

hr {
  border: 0;
  border-top: 2px solid $light-grey;
}

.header-spacer {
  display: block;
  height: 50px;
  width: 100%;

  &--l {
    @extend .header-spacer;

    height: 75px;
  }

  &--xl {
    @extend .header-spacer;

    height: 100px;
  }
}

.page {
  position: relative;
  width: 100vw;
  padding-top: 55px;
  padding-right: 40px;
  background: $background;

  @media only screen and (max-width: 1000px) {
    padding-right: 20px;
  }

  @include softphone-only {
    padding: 0;
  }
}

//logo
.LogoSidebar {
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 6s;
  transform-style: preserve-3d;
  text-align: center;
}

/* WebKit and Opera browsers */
@keyframes spinner {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(-360deg);
  }
}

/* all other browsers */
@keyframes spinner {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(-360deg);
  }
}

.dashboard-text {
  font-size: 14px;
  color: #252423;
  padding-top: 15px;
}

.LogoSidebar {
  margin: 0 auto;
}

.dashboard-text:hover {
  text-decoration: none;
}

.component-title {
  display: flex;
  align-items: center;
  font-size: $font-size-extra-large;
  font-weight: $font-weight-bold;
  color: $dark-blue;
  margin-top: 0;
  margin-bottom: 20px;
  height: 25px;

  > span {
    margin-right: 10px;
  }
}

.panel {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 5%) 0 1px 2px 0;
  padding: 30px;
  min-height: 400px;
  display: flex;
  flex-direction: column;

  &--small {
    @extend .panel;

    min-height: unset;
  }

  &--no-padding {
    @extend .panel;

    padding: 0;
  }

  &--elevated {
    @extend .panel;

    box-shadow: rgb(0 0 0 / 5%) 0 4px 10px 0, rgb(0 0 0 / 8%) 0 0 0 1px;
  }

  &--small-elevated {
    @extend .panel;

    min-height: unset;
    box-shadow: rgb(0 0 0 / 5%) 0 4px 10px 0, rgb(0 0 0 / 8%) 0 0 0 1px;
  }

  &--inherit-height {
    @extend .panel;

    height: inherit;
  }
}

.component-title-second {
  font-family: $font;
  font-size: 20px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  text-align: left;
}

.content-wrapper {
  padding: 20px;
  height: 100%;
}

.statistics2 {
  height: 150px;
  font-size: 70px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0;
  padding: 20px;
  position: relative;

  .info {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }
}

.medium .statistics2 {
  font-size: 30px;
}

.large .statistics2 {
  font-size: 70px;
}

.floating-data {
  position: absolute;
  bottom: 0;
  font-size: 40px !important;
}

.medium .floating-data {
  font-size: 20px !important;
}

.large .floating-data {
  font-size: 40px !important;
}

.statistics3 {
  height: 30px;
  font-size: 20px;
  font-weight: $font-weight-medium;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0;
}

.medium .statistics3 {
  font-size: 14px;
}

.large .statistics3 {
  font-size: 20px;
}

.MuiFormControl-root {
  width: 100%;
}

.pop-select-widget {
  position: absolute;
  top: 159px;
  right: 0;
  width: 200px;
  background-color: black;
  color: white;
  z-index: 1;
  height: 300px;
  overflow: scroll;
}

.pop-select-list {
  overflow: scroll;
}

.threshold-warning-flasher {
  animation: warningflasher 3s linear infinite !important;
}

@keyframes warningflasher {
  50% {
    background-color: orange;
  }
}

.threshold-alert-flasher {
  animation: alertflasher 3s linear infinite !important;
}

@keyframes alertflasher {
  50% {
    background-color: red;
  }
}

// -- Modal -- //
// (Horrible hacky selectors because we can't pass classnames and the modal/elements don't have classnames)

div[aria-labelledby='modal'] {
  transform: translateZ(0);

  //z-index: 9999 !important;

  > div:first-of-type {
    background-color: #edf2f5 !important;
    opacity: 0.8;
  }
}

// -- Form -- //

// Label
.MuiFormLabel-root {
  margin-bottom: 0 !important;
  font-size: 14px !important;
}

// Line under input
.MuiInput-underline:not(.Mui-error)::after {
  border-bottom-color: $blue !important;
}

// -- Tables -- //

// Selected rows panel
.mui-table > .MuiPaper-root {
  background-color: #f5f5f5 !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px;
  padding-right: 5px;

  // Row panel selection text
  .MuiTypography-root {
    white-space: nowrap;
  }
}

.mui-table .MuiTableCell-paddingCheckbox {
  z-index: 1 !important;
}

// Table Headers
.MuiTableCell-head {
  z-index: 1 !important;
}

.MuiTableCell-head .MuiButton-label {
  font-weight: 600 !important;
}

.MuiFormControl-root {
  max-width: 400px;
}

.MuiToolbar-root {
  min-height: 64px !important;
  margin-bottom: 10px;
  padding-right: 0 !important;
}

.MuiTableRow-root.Mui-selected {
  background-color: $mid-grey !important;

  &:hover {
    background-color: rgb(17 181 231 / 30%) !important;
  }
}

// -- Checkbox -- //

.MuiCheckbox-colorPrimary.Mui-checked {
  color: $blue !important;
}

// -- HR -- //

hr {
  margin-top: 40px;
  margin-bottom: 40px;
}

.relative {
  position: relative;
}

.min-height-300 {
  min-height: 300px;
}

// -- MUI Paper -- //
// Ditch the shadow everywhere. If we need shadow, use .panel

.MuiPaper-root {
  box-shadow: none !important;
}

// -- Popovers -- //

.MuiPopover-paper.MuiPaper-root {
  @include popup;
}

.configuration-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #edf2f5 !important;
  opacity: 0.8;
  z-index: 1006;
  outline: none;
  border: none;
  padding: 0;
}

.configuration-popup {
  @extend .panel;

  box-shadow: rgb(0 0 0 / 5%) 0 6px 24px 0, rgb(0 0 0 / 8%) 0 0 0 1px;
  padding: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 1007;
  width: 50%;
  max-width: 500px;

  @media only screen and (max-width: 600px) {
    max-width: unset;
    width: calc(100% - 40px);
  }

  h2 {
    margin-bottom: 40px;
  }

  &__footer {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

    > button:first-of-type {
      margin-right: 20px;
    }
  }
}

// -- MUI Datepicker -- //
.MuiPickersToolbar-toolbar {
  background: $blue !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: white !important;
}

.MuiPickersDay-daySelected {
  background: $blue !important;
}

.MuiPickersModal-dialogRoot .MuiDialogActions-root .MuiButton-root {
  text-transform: unset !important;
  color: $primary !important;
}

// -- MUI Outlined TextField -- //

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $blue !important;
  border-width: 1px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $blue !important;
}

// Makes the input height 45px
.MuiTextField-root input,
.MuiSelect-root {
  padding-top: 13px !important;
  padding-bottom: 13px !important;

  @include softphone-only {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

.MuiTextField-root {
  width: 100%;
}

.MuiInputBase-root {
  @include softphone-only {
    height: 35px;
    min-height: 35px;
  }
}

.MuiInputBase-multiline {
  @include softphone-only {
    height: inherit;
    min-height: inherit;
  }
}

.MuiTextField-root label {
  top: -4px !important;

  @include softphone-only {
    top: -8px !important;
  }
}

.MuiTextField-root label[data-shrink='true'] {
  top: 0 !important;
}

.MuiFormControl-root {
  max-width: unset !important;
}

.MuiInputBase-root > .fa-chevron-down {
  position: absolute;
  right: 13px;
  pointer-events: none;
  color: $dark-grey;
}

// Toolip

.MuiTooltip-tooltip {
  background: $white !important;
  border: 1px solid $mid-grey;
  color: $text !important;
  font-weight: 400 !important;
  padding: 5px 15px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}

// MUI Menu List //

.MuiMenu-list {
  @include scrollable-container;
}