@import 'vars';

.content-panel {
  &__header {
    margin-bottom: 40px;

    &__title{
      display: flex;

      > h2 {
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    color: $dark-grey;
    margin-bottom: 0;
    font-size: $font-size-small;
    margin-top: 10px;
  }

  &__icon {
    margin-left: 10px;
  }

  &--empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    > h2 {
      margin-top: 20px;
      margin-bottom: 0;
    }

    > svg {
      width: 30%;
      height: auto;
      max-height: 200px;
    }
  }
}
