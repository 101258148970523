@import 'vars';

.campaigns {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__add {
    position: absolute !important;
    right: 0;
    top: 28px;
  }
}
