@import 'vars';

@keyframes slide-out {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.2;
  }

  90% {
    transform: translate(-50px, 0);
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-in {
  0% {
    transform: translate(-50px, 0);
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.8;
  }

  90% {
    transform: translate(0);
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes wheel-scroll {
  0% {
    opacity: 1;
    top: 3px;
  }

  30% {
    opacity: 0;
    top: 8px;
  }

  100% {
    opacity: 0;
    top: 10px;
  }
}

.task-list {
  position: relative;
  flex: 1;
  overflow: auto;
  padding: 0px 20px;

  &__scroll {
    width: 15px;
    height: 23px;
    border: 2px solid $mid-grey;
    border-radius: 40px;
    position: relative;
    margin-left: 10px;

    &::before {
      content: '';
      width: 3px;
      height: 5px;
      position: absolute;
      top: 2.5px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $dark-grey;
      border-radius: 50%;
      opacity: 1;
      animation: wheel-scroll 5s infinite;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;

    > *:last-child:not(:first-child) {
      margin-left: auto;
    }
  }

  &__selected-task {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &__spacer {
      height: 60px;
      min-height: 60px;
      margin-bottom: 10px;
    }
  }
}


.selected-task {
  &-enter {
    opacity: 0;
    animation: slide-in 0.7s ease;
  }

  &-enter-active {
    opacity: 1;
  }
}

.task {
  &-enter {
    opacity: 0;
    animation: slide-in 0.7s ease;
  }

  &-enter-active {
    opacity: 1;
  }

  &-exit {
    opacity: 0;
    animation: slide-out 0.7s ease;
  }

  &-exit-active {
    opacity: 0.01;
  }
}

.no-task-placeholder {
  text-align: left;

  &-enter {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &-enter-active {
    opacity: 1;
    height: 100%;
  }

  &-exit {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &-exit-active {
    opacity: 0.01;
    height: 0;
  }
}