@import 'vars';

.dashboard-select {
  z-index: 1;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  .MuiSelect-root {
    font-size: $font-size-extra-large;
    font-weight: $font-weight-bold;
    color: $dark-blue;
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 35px 0 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    margin-top: 2px;
    right: 5px;
    color: $text !important;
  }
}
