@import 'vars';

.signed-out-overlay {
  background: $white;
  position: fixed;
  z-index: 996;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 10%;

  > svg {
    max-width: 600px;
    margin-left: 40px;
    width: 50%;

    @media only screen and (max-width: 1100px) {
      display: none;
    }
  }
}