@import 'vars';
@import 'mixins';

.queues {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    &__icons {
      display: flex;
      position: relative;

      &__icon {
        width: 65px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}