@import 'mixins';

.softphone-task {
  @include scrollable-container;

  flex: 1;
  display: flex;
  flex-direction: column;

  // Add margin bottom to the 2nd-last element in a task. Task footer is the last child.
  > *:nth-last-child(2) {
    margin-bottom: 20px;
  }
}