@import 'vars';
@import 'mixins';

:root {
  --toastify-color-light: #{$white} !important;
  --toastify-color-dark: #{$text} !important;
  --toastify-color-info: #{$white} !important;
  --toastify-color-success: #{$white} !important;
  --toastify-color-warning: #{$white} !important;
  --toastify-color-error: #{$white} !important;
}

.Toastify__toast-container {
  @include softphone-only {
    // 40px sidebar + 15px padding left + right
    max-width: calc(100vw - 40px - 30px);
  }
}

.Toastify__toast {
  box-shadow: none !important;

  // Override the default toastify media styles back to normal
  @media only screen and (max-width: 480px) {
    margin-bottom: 10px !important;
    border-radius: 4px !important;
    left: 55px;
  }
}

.Toastify__close-button {
  opacity: 1 !important;

  > svg {
    color: $dark-blue !important;
  }
}

.Toastify__toast--info {
  color: $text !important;
  border: 2px solid $text;
}

.Toastify__progress-bar--info {
  background: $text !important;
  opacity: 0.15 !important;
}

.Toastify__toast--warning {
  color: $text !important;
  border: 2px solid $warning;
}

.Toastify__progress-bar--warning {
  background: $warning !important;
  opacity: 0.15 !important;
}

.Toastify__toast--error {
  color: $text !important;
  border: 2px solid $danger;
}

.Toastify__progress-bar--error {
  background: $danger !important;
  opacity: 0.15 !important;
}

.Toastify__toast--success {
  color: $text !important;
  border: 2px solid $success;
}

.Toastify__progress-bar--success {
  background: $success !important;
  opacity: 0.15 !important;
}

.Toastify__close-button > svg {
  color: $text;
}