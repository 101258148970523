.agent-workspace {
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;

  // 55px header, 40px padding top, 40px padding bottom
  max-height: calc(100vh - 135px);

  @media only screen and (max-width: 1000px) {
    // 55px header, 20px padding top, 20px padding bottom
    max-height: calc(100vh - 95px);
  }

  &__profile-unsupported {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      max-height: 300px;
    }
  }

  &__attached-data {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }
}
