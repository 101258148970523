@import 'vars';

.add-agent-task-button {
  border: 0;
  padding: 0;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $primary;
}