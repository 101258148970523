@import 'vars';
@import 'mixins';
 
.chat-messages {
  &__container {
    @include scrollable-container;
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  &__start {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    text-align: center;
    margin-top: auto;

    > p {
      margin-top: 10px;
    }
  }

  &__messages {
    &__block {
      &__date {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        > span {
          height: 1px;
          width: 100%;
          background: $mid-grey;
        }

        > p {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}