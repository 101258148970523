@import 'mixins';

.agent-task {
  @include scrollable-container;

  flex: 1;
  min-height: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}