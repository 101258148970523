.agent-workspace-column {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  min-height: unset;

  &--resizable:not(&--vertical) {
    padding-left: 20px;
  }

  &--resizable.agent-workspace-column {
    &--vertical {
      padding-top: 40px;
    }
  }
}
