.task-references {
  &__list {
    margin-top: 40px;

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      > *:first-child {
        margin-right: 10px;
      }
    }
  }
  
  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 10px;
    }

    &__link {
      cursor: pointer;
    }
  }
}