@import 'vars';

.tabs {
  position: relative;

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
  }

  &__tab-content {
    display: flex;
    flex: 1;
  }

  &__selected-line {
    background: $blue;
    height: 2px;
    position: absolute;
    bottom: 0;
    transition: left 0.2s ease-in-out;
  }
}
