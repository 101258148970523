@import 'vars.scss';

.task-references {
  &__reference {
    display: flex;
    align-items: center;

    > div {
      flex: 1;
    }

    > button {
      margin-left: 20px;
    }

    &:not(:last-of-type) {
      margin-bottom: 20px;
      border-bottom: 1px solid $mid-grey;
      padding-bottom: 20px  ;
    }
  }

  &__button {
    &:not(&--only-child) {
      margin-top: 20px !important;
    }
  }
}
