@import 'vars';
@import 'mixins';

.agent-status-timer {
  flex: 1;
  display: flex;
  justify-content: end;

  &__container {
    transition: all ease-in-out 0.5s;
    background: $yellow;
    width: fit-content;
    border-radius: 4px;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
  
    &__text {
      margin-left: 8px;
      font-weight: bold;
    }

    &__popover-text p {
      margin-left: unset;
      text-align: center;
    }

    @include softphone-only {
      height: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__container-popover {
    cursor: pointer;
  }

  &-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
}