@import 'vars.scss';
@import 'mixins';

.create-agent-task {
  &__text-field {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    .MuiInputBase-root {
      background: white !important;
    }
  }

  &__date-time {
    .MuiInputBase-root {
      background: white !important;
    }

    &__date {
      width: 60%;
      margin-right: 5% !important;
  
      @include small-softphone-only {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  
    &__time {
      width: 35%;
  
      @include small-softphone-only {
        margin-top: 15px !important;
        width: 100%;
      }
    }

    @include small-softphone-only {
      display: block;
      
      .MuiOutlinedInput-adornedEnd { 
        padding-right: unset;
      }

      &__date {
        width: 100%;
        margin-right: 0 !important;
      }
    
      &__time {
        margin-top: 15px !important;
        width: 100%;
      }
    }
  }
  
  &__tabs {
    margin-bottom: 20px;
    max-width: 265px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    background: $white;
    position: sticky;
    bottom: 0;

    > button {
      margin-left: 10px;
    }
  }

  // Adding some padding to avoid MUI textfield labels being cut off due to the accordion overflow: hidden.
  &--padding {
    padding-top: 5px;
  }
}
