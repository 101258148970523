@import 'vars';

$width: 400px;

.incoming-task-overlay {
  width: 100vw;
  pointer-events: none;
  z-index: 999;
  bottom: 30px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &--top {
    top: 75px;
    bottom: unset;
    flex-direction: column-reverse;
  }

  &__content {
    &--top {
      margin-top: 10px;
      flex-direction: column-reverse;
    }

    &--multiple {
      width: 390px;
    }

    transition: all 0.5s ease-in-out;
    height: max-content;
    width: 350px;
    display: flex;
    flex-direction: column;
    z-index: 99999;
    overflow: hidden;
    box-shadow: unset;
    padding: 0;
    border-radius: 8px;
    background-color: transparent;

    &.animate__slideInRight {
      margin-right: 20px;
    }

    &__task-gap {
      margin-top: 10px;
    }

    &--small {
      margin-top: 10px;
      border-radius: 50% !important;
      height: 75px;
      width: 75px;
      min-width: 75px;
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 0;
      padding: 0;
      cursor: pointer;
      overflow: visible;
      background: $dark-blue;

      &:hover {
        background: lighten($dark-blue, 5%);

        .incoming-task-overlay {
          &__content {
            &__bubble {
              &__quantity {
                top: 0;
              }
            }
          }
        }
      }
    }

    &__bubble {
      border: 0;
      padding: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      background: none;
      pointer-events: all;

      &__quantity {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        position: absolute;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        color: $dark-blue;
        border: 2px solid $dark-blue;
      }
    }
  }
}
