@import 'vars';
@import 'mixins';

.agent-icon {
  position: relative;
  width: 65px;
  display: flex;
  justify-content: center;

  @include softphone-only {
    width: 30px;
  }

  &__icon {
    transform: scaleX(-1);
  }

  &__dot {
    background-color: $success;
    width: 5px;
    height: 5px;
    right: 16px;
    border-radius: 50%;
    position: absolute;
    bottom: 2px;

    &--small {
      right: 3px;
      bottom: 0;
    }
  }
}