@import 'vars';

$popover-menu-padding: 16px;

.user-profile {
  &__wrapper {
    display: flex;
  }

  &__divider {
    margin: 5px $popover-menu-padding 5px;

    &:first-of-type:not(:last-of-type) {
      margin-top: 20px;
    }
  }

  &__username-status-wrapper {
    margin-left: 10px;
    text-align: left;

    &--softphone {
      padding-left: $popover-menu-padding;
      padding-right: $popover-menu-padding;
      padding-top: 10px;
      margin-left: 0;
    }
  }

  &__username {
    font-size: $font-size-extra-small;
    font-weight: $font-weight-bold;
    padding-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    text-align: left;
  }
}
