@import 'vars';

$width: 400px;

.incoming-task {
  max-width: 350px;
  margin-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: unset;
  padding: 10px;
  border-radius: 12px;
  border-top-left-radius: 0px;
  background: $dark-blue;

  .task-overlay-banner__header {
    padding: 5px 10px;
  }

  hr {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}
