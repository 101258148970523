@import 'vars';
@import 'mixins';

.phone-input {
  border: 1px solid #CACACA;
  border-radius: 4px;
  display: flex;
  height: 45px;
  min-height: 45px;

  @include softphone-only {
    height: 40px;
    min-height: 40px;

    > .MuiInputBase-root {
      height: 38px;
      min-height: 38px;
    }
  }

  &__input {
    background: $white;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &__countries {
    width: 80px;

    @include softphone-only {
      height: 38px;
      min-height: 38px;

      &__control {
        height: 38px !important;
        min-height: 38px !important;
      }
    }

    &__country-option {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // Override the option when rendered in the list of countries (otherwise will impact the selected option)
    &__menu-list {
      .phone-input__countries__country-option {
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &__country-details {
      display: flex;
      width: 140px;
      justify-content: space-between;
      align-items: center;
    }

    .country-details-selected {
      display: none;
    }

    &__option {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:focus-within {
    border: 1px solid $blue;
  }

  &:hover:not(:focus-within) {
    border: 1px solid black;
  }

  &--error {
    border: 1px solid $danger !important;
  }

  &--disabled {
    border: 1px solid #CACACA !important;
  }
}

.helper-text {
  color: rgba(0, 0, 0, 0.54);
  margin: 3px 14px 0 14px !important;
  
  &--disabled {
    color: rgba(0, 0, 0, 0.38);
  }
}
