.no-tasks-placeholder {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;

  > svg {
   opacity: 0.5;
  }

  &__text {
    margin-top: 40px;
    margin-bottom: 40px;

    > * {
      margin: 0;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}
