@import 'vars';

.loader {
  width: 100px;
  height: 100px;
  z-index: 1;

  > div {
    display: flex;
  }
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1005;
  pointer-events: none;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 12px;

  &--relative {
    position: unset;
    transform: unset;
    margin: auto;
    z-index: 1;
  }

  &--small {
    &::after {
      width: 50px;
      height: 50px;
    }
  }

  &--fixed {
    position: fixed;
    height: 100vh;
    width: 100vw;
  }

  &--elevated {
    &::after {
      box-shadow: rgb(0 0 0 / 5%) 0 1px 2px 0;
    }
  }
}
