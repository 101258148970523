@import 'vars';

.core-layout {
  &__content {
    display: flex;
    position: relative;

    &__right {
      padding-top: 40px;
      flex: 1;
      min-width: 0;
      margin-left: 40px;
      position: relative;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 1000px) {
        padding-top: 20px;
        margin-left: 20px;
        padding-bottom: 20px;
      }

      &__title {
        justify-content: space-between;
        line-height: $font-size-extra-large;
      }
    }
  }

  &--fullscreen {
    width: calc(100% + 20px);
    margin-top: -50px;
    padding-left: 20px;

    .react-grid-layout {
      width: 100%;
    }
  }
}
