@import 'vars';
@import 'mixins';

.agent-stats {
  flex: 1;
  display: flex;
  min-height: 156px;
  flex-direction: column;
  height: 100%;

  @include softphone-only {
    flex: 1;
  }

  > .loader-container {
    margin: auto;
  }

  &__queues {
    @include scrollable-container;

    flex: 1;
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;

    > hr {
      margin: 20px 0;
      border: 0;
      border-top: 2px solid $light-grey;

      @include softphone-only {
        display: none;
      }
    }

    @include softphone-only {
      height: 100%;
      display: flex;
      flex-direction: column;

      > hr {
        display: none;
      }
    }

    &__warning {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      background: $pastel-yellow;
      border-radius: 6px;
      width: 100%;
      height: 30px;
      min-height: 30px;
      margin-top: 30px;
      position: sticky;
      bottom: 0;

      > p {
        font-size: 12px;
        margin-left: 10px;
      }

      @include softphone-only {
        margin-top: 15px;
        justify-content: center;
        margin-left: unset;
        width: unset;
      }

      @include small-softphone-only {
        justify-content: left;
      }
    }

    &__header {
      position: sticky;
      top: 0;
      background: $white;
      z-index: 2;
      margin-bottom: 40px;

      @include softphone-only {
        margin-bottom: 20px;
      }

      &__title {
        margin-bottom: 10px;
      }
  
      &__stats {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;

        @include small-softphone-only {
          gap: 15px;
        }

        > p {
          display: flex;
          align-items: center;
          height: 20px;
          white-space: nowrap;
        }

        &__stat {
          margin-left: 10px;

          @include softphone-only {
            margin-left: 5px;
          }
        }

        &__number {
          padding-bottom: 2px;
        }

        > * span {
          font-weight: $font-weight-bold;
          color: $dark-blue;
          font-size: $font-size-extra-large;
          margin-left: 8px;
          line-height: 20px;

          margin-top: -2px;
        }
      }
    }
  }
}
