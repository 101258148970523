@import 'vars';

.after-contact-work {
  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    margin-bottom: 20px;
    
    &__row {
      display: flex;

      > *:last-child {
        align-self: center;
        margin-left: 10px;
        font-size: $font-size-normal;
      }
    }

    &__row-chat {
      > a {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}