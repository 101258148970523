@import 'vars';

.isolated-layout {
  background: $background;
  padding: 20px;
  //min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__children {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}