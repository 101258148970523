@import 'vars';

.heading-label {
  display: flex;
  align-items: center;
  color: $text;
  margin-bottom: 18px;

  &--no-margin {
    margin-bottom: unset;
  }

  p {
    padding-top: 3px;
  }
  &--small &__dot,
  &--small > p {
    padding-top: unset;
  }

  &__dot {
    color: inherit;
    font-size: 10px;
    margin: 0 10px 0;
    padding-top: 3px;
  }

  > p {
    color: $dark-grey;
  }
}
