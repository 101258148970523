@import 'vars';
@import 'mixins';

.after-contact-work {
  @include scrollable-container;
  margin-bottom: auto;

  &__divider {
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid $mid-grey;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__info {
    margin-bottom: 40px;

    &__row {
      display: flex;

      > svg {
        width: 20px;
      }

      > *:last-child {
        margin-left: 10px;
        font-size: $font-size-normal;
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    &__row-chat {
      > a {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}