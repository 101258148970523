@import "~animate.css/animate.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "meny";
@import "typography";
@import "~react-resizable/css/styles.css";
@import "~react-grid-layout/css/styles";

@import "utils/index";

.contents {
  padding: 0 !important;
}

.meny {
  background: #f2f5f8;
}

/* suppress focus ring on form controls for mouse users */
[data-whatintent="mouse"] *:focus {
  outline: none !important;
}
