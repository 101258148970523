@import 'vars';

.country-flag {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(./assets/flags.png) no-repeat;

  &--missing {
    background: $light-grey;
    height: 16px;
    width: 22px;
    vertical-align: middle;
    margin-bottom: 1px;
    border: 1px solid $mid-grey;
    border-radius: 2px;
  }
}
