// -- UI Kit Colours --

$light-grey: #f7f8fa;
$mid-grey: #e0e0e0;
$dark-grey: #8c929a;
$background: #f4f6fa;
$primary: #ed3d80;
$blue: #11b5e7;
$success: #35c759;
$danger: #f83b2f;
$dark-blue: #101e47;
$yellow: #ffdd19;
$warning: #ff9500;
$black: #111;
$white: #fff;
$text: $black;
$text-red: darken($danger, 15%);

$pastel-blue: #b7dcf3;
$pastel-green: #b4e4c9;
$pastel-orange: #f6d3af;
$pastel-red: #ff6961;
$pastel-yellow: #fbeba5;

// -- Popper width --

$overlay-large-number: 500;
$overlay-small-number: 350;

$overlay-large: $overlay-large-number + px;
$overlay-small: $overlay-small-number + px;

// -- Font weight --

$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-book: 400;

// -- Font size --

$font-size-extra-large: 24px;
$font-size-large: 18px;
$font-size-normal: 15px;
$font-size-small: 14px;
$font-size-extra-small: 12px;

$font-size-extra-large-mobile: 20px;
$font-size-large-mobile: 16px;

// -- Font --
$font: 'Circular', sans-serif;

// -- Media Sizes --

$softphone-max-width-number: 800;
$softphone-max-width: $softphone-max-width-number + px;
$softphone-small-width-number: 400;
$softphone-small-width: $softphone-small-width-number + px;
