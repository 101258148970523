@import 'vars';
@import 'mixins';

.upsert-contact {
  @include scrollable-container;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    > .clickable-icon {
      width: unset;
      min-width: unset;
      margin: unset;
      margin-right: 20px;
    }
  }

  &__row {
    margin-bottom: 35px;

    &__username {
      margin-bottom: 35px;
      display: flex;

      > div:first-of-type {
        margin-right: 12px;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;

      &__single {
        justify-content: flex-end;
      }
    }
  }

  &__number-type {
    &__error {
      border: 1px solid $danger !important;
      border-radius: 4px;

      .select {
        &__control {
          border: none;
        }
      }
    }
  }

  &__type-error {
    color: $danger !important;
  }
}