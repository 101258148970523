@import 'vars';
@import 'mixins';

.softphone-layout {
  height: 100vh;
  min-width: 320px;

  &__content {
    display: flex;
    position: relative;
    height: calc(100% - 40px);

    &--reversed {
      flex-direction: row-reverse;
    }

    &__container {
      @include scrollable-container;
      flex: 1;
      flex-direction: column;
      background: $white;
      display: flex;

      &__children {
        padding: 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
