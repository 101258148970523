@import 'vars';

.softphone-nav-menu-item {
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background: unset;
  border: unset;
  cursor: pointer;
  
  > *svg {
    color: $dark-blue;
  }

  &--active {
    background: $blue;
    border-radius: 2px;

    > *svg {
      color: $white;
    }
  }
}