@import 'vars';

.label-wrapped {
  display: flex;
  flex-direction: column;

  > label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  > *:last-child {
    margin-bottom: auto;
    width: 100%;
  }

  &__required {
    color: $danger;
    margin-left: 5px;
  }
}
