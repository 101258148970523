@import 'vars';

.softphone-sidebar {
  width: 40px;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  background: $white;
  border-right: 2px solid $background;
  justify-content: end;

  &--reversed {
    border-right: unset;
    border-left: 2px solid $background;
  }
  
  &__top {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow: hidden;
  }
  

  &__nav-menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow: hidden;
    row-gap: 10px;
  }
}