@import 'vars';

.auth-error {
  background: white;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__user {
    position: fixed;
    top: 40px;
    right: 40px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1000px) {
      top: 20px;
      right: 20px;
    }

    &__avatar {
      background: $light-grey;
      color: $mid-grey;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__info {
      margin-left: 15px;
      margin-right: 30px;

      > * {
        margin: 0;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    margin-top: auto;
    margin-bottom: auto;

    @media only screen and (max-width: 1000px) {
      padding: 20px;
    }

    > svg {
      height: auto;
      max-width: 700px;
      position: absolute;
      right: -30px;

      @media only screen and (max-width: 1200px) {
        width: 40%;
      }

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    &__right {
      margin-right: 20%;

      @media only screen and (max-width: 1700px) {
        margin-right: 30%;
      }

      @media only screen and (max-width: 1500px) {
        margin-right: 50%;
      }

      @media only screen and (max-width: 1000px) {
        margin: auto;
        padding: 20px;
      }

      > h1 {
        margin-bottom: 20px;
      }

      &__help {
        margin-top: 40px;

        @media only screen and (max-width: 1000px) {
          margin-top: 20px;
        }

        &__item {
          display: flex;
          align-items: center;
          max-width: 600px;

          &:not(:last-of-type) {
            margin-bottom: 30px;
          }

          &:last-of-type {
            margin-top: 40px;

            @media only screen and (max-width: 1000px) {
              margin-top: 20px;
            }
          }

          > p {
            margin-bottom: 0;
            margin-left: 20px;
          }

          > svg {
            color: $text;
          }
        }
      }
    }
  }
}
