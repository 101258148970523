@import 'vars';
@import 'mixins';
 
.chat-task {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  max-height: 400px;

  &__send {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @include softphone-only {
      margin-top: 15px;
    }

    &__input {
      background-color: $white;
    }

    &__button {
      margin-left: 10px !important;
    }
  }
}