@import 'vars';

.number-pad {
  &__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__row {
    display: flex;

    &__digit {
      display: flex;
      font-size: $font-size-large;
      font-weight: $font-weight-medium;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      width: 100px;
      height: 75px;
      min-height: 75px;
      min-width: 100px;
      justify-content: center;

      &--small {
        width: 85px;
        min-width: 85px;
      }

      &:hover {
        background-color: $light-grey;
        border-radius: 4px;
      }

      > .sub {
        font-size: $font-size-extra-small;
        font-weight: $font-weight-book;
      }
    }
  }
}