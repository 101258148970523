@import 'vars';

.profile-notes {
  margin-bottom: 30px;

  &__preview {
    max-height: 300px;
    overflow-y: auto;

    > * {
      font-size: 16px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    right: 30px;
    font-size: 20px;
    margin-left: auto;

    > *:first-child:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__toolbar {
    &__saving {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__main-editor {
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    margin-top: 20px;
    padding: 6px 10px;
    min-height: 100px;

    &__saving {
      color: $dark-grey;
      background-color: $light-grey;
    }
  }
}
