@import 'mixins';
@import 'vars';

.toast-alert {
  display: flex;
  align-items: center;

  @include softphone-only {
    > svg {
      font-size: 15px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    > h3 {
      font-size: $font-size-normal;
      line-height: 18px;
      margin-bottom: 3px;

      @include softphone-only {
        font-size: $font-size-extra-small;
        line-height: 14px;
      }
    }

    > p {
      font-size: $font-size-extra-small;
      line-height: 14px;
    }

  }
}
