@import 'vars';

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(17 181 231 / 70%);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgb(17 181 231 / 0%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(17 181 231 / 0%);
  }
}

.header {
  // Account for nav/side bar
  width: 100vw;
  right: 0;
  height: 55px;
  position: fixed;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-right: 40px;
  box-shadow: rgb(0 0 0 / 5%) 0 1px 2px 0;
  top: 0;
  z-index: 2;

  &__widget-overlay {
    max-height: 80vh;

    > .draggable-overlay {
      &__content {
        &--large {
          max-height: unset;
        }
      }
    }
  }

  > .clickable-icon,
  &__documents,
  &__whats-new {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 10px;
    border: 0;
    padding: 0;
  }

  &__whats-new {
    margin-right: 20px;

    &--unread {
      background: rgb(17 181 231 / 100%) !important;
      box-shadow: 0 0 0 0 rgb(17 181 231 / 100%);
      animation: pulse-blue 2s infinite;
      > svg {
        color: white;
      }
    }
  }

  &__logo {
    height: auto;
    width: 120px;
  }

  &__burger {
    width: 60px;
    display: flex;
    justify-content: center;
  }

  &__end {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__username {
      font-weight: $font-weight-bold;
      font-size: 14px;
      line-height: 18px;
    }

    &__status {
      font-size: 12px;
      color: #131313;
    }
  }

  .user-profile {
    &__wrapper {
      margin-left: 20px;
    }
  }
}
