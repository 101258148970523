@import 'vars';

.information-field {
  display: flex;
  font-size: 15px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  > span:first-of-type {
    margin-right: 30px;
    color: $text;
    min-width: 120px;
  }
}
