@import 'vars';

.softphone-task-list {
  text-align: center;
  flex: 1 1;
  height: 100%;
  margin-bottom: 60px;
  min-height: 90px;
    
  > hr {
    border: 0;
    border-top: 1px solid $mid-grey;
    margin: 0 8px 10px 8px;
  }

  &__item {
    margin: 5px;
    padding: 5px;
    background: $light-grey;
    border-radius: 2px;
    height: 30px;

    &--active {
      background: $blue;
      
      > *svg {
        color: $white;
      }
    }

    &--incoming {
      background: $dark-blue;
      
      > *svg {
        color: $white;
      }
    }
  }


  &__more-tasks {
    margin: 5px;
    padding: 5px;
    background: $light-grey;
    border-radius: 2px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    font-weight: $font-weight-bold;

    &--active {
      background: $blue;
      color: $white;
    }

    &__item {
      > *svg {
        font-size: 18px !important;
        width: 18px !important;
        height: 18px !important;
      }

      > li {
        margin: 0 5px;
        border-radius: 6px;
      }

      &--active {
        .MuiListItem-button {
          &:hover {
            background-color: $blue;
          }
        }
        
        > li {
          background: $blue;

          > p {
            color: $white;
          }
        }
        
        > *svg {
          color: $white;
        }
      }
    }
    
  }
}