@import 'vars';
@import 'mixins';

.notification-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: $pastel-yellow;
  font-size: $font-size-extra-small;
  padding: 8px;

  &__text {
    margin-right: 10px;
    margin-left: 10px;

    &__link {
      font-size: $font-size-extra-small;
    }
  }
}
