@import 'vars';
@import 'mixins';

.directory {
  // 24px tabs + 80px search + 366px list (470 min required for dialpad)
  height: 470px;
  display: flex;
  flex-direction: column;

  &--fill-container {
    height: 100%;
  }

  > .loader-container {
    margin: auto;
  }

  > hr {
    margin: 20px 0;
    border-top: 2px solid $light-grey;
  }

  &__tabs {
    position: sticky;
    top: 0;
    background: $white;
    z-index: 2;
  }

  &__action-menu {
    display: flex;
    width: 200px !important;
    justify-content: space-between !important;
    padding: 8px 16px;

    &__item {
      font-size: $font-size-normal;
    }
  }

  &__phone-search {
    display: flex;
    justify-content: space-between;
    height: 60px;
    min-height: 80px;
    align-items: center;

    input {
      font-size: $font-size-small;
    }

    .clickable-icon {
      width: unset;
      min-width: unset;
      margin: unset;
      margin-left: 15px;
    }

    .searchbar-wrapper {
      width: 100%;
    }
  }

  &__phone-container {
    overflow-y: auto;

    .ReactVirtualized__List {
      @include scrollable-container;
    }
  }

  &__phone-row {
    display: flex;
    justify-content: space-between;
    overflow: visible;
    padding: 5px;
    align-items: center;

    &--hoverable {
      cursor: pointer;

      &:hover {
        &::before {
          content : '';
          display: block;
          background: $light-grey;
          width: 100%;
          position: absolute;
          height: 50px;
          z-index: 0;
          border-radius: 6px;
          left: 0;
        }
      }
    }
  }

  &__phone-user-profile {
    display: flex;
    z-index: 1;
    position: relative;
    height: 40px;
    align-items: center;

    &__user-details {
      margin-left: 10px;

      &--small {
        font-size: 14px;
      }

      &__phoneNumber-wrapper {
        display: flex;
        align-items: center;

        > span:first-of-type {
          margin-right: 4px;
        }
      }

      &__phoneNumber {
        display: block;
        color: $dark-grey;
        font-size: $font-size-extra-small;
      }

      &__firstName,
      &__lastName {
        font-weight: $font-weight-bold;
      }

      &__lastName {
        margin-left: 2px;
        display: inline-block;
        vertical-align: bottom;

        &--ellipsis {
          max-width: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__info {
        margin-left: 5px;
      }

      &__organisation {
        .MuiTooltip-tooltip {
          background-color: $white;
          border: 1px solid $mid-grey;
          padding: 16px;

          > p:first-of-type {
            font-weight: $font-weight-bold;
          }

          > p:last-of-type {
            margin: unset;
            font-size: $font-size-extra-small;
          }
        }

        .MuiTooltip-arrow {
          width: 16px;
          height: 7px;
          color: $white;
        }

        .MuiTooltip-tooltipPlacementTop {
          margin: unset;
        }

        .MuiTooltip-arrow::before {
          border: 1px solid $mid-grey;
        }
      }
    }
  }

  &__contact-actions {
    display: flex;
    z-index: 1;
    position: relative;
    height: 40px;

    > .clickable-icon {
      width: unset;
      min-width: unset;
      margin: unset;
      margin-left: 10px;
    }

    &__in-progress {
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }
}
