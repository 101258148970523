.task-actions {
  display: flex;

  > *:first-child:not(:last-child) {
    margin-right: 10px;
  }

  &__expanded {
    justify-content: center;
    width: 100%;
  }
}
