@import 'vars';

$spacer-width-px: 25;

.resizable-group {
  &--vertical {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__container {
    display: flex;
    height: 100%;
    position: relative;

    &--vertical {
      width: 100%;
      align-items: unset;
    }
  }

  &__spacer {
    height: 100%;
    width: #{$spacer-width-px}px;
  }

  &__resizer {
    height: 100%;
    width: #{$spacer-width-px}px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    cursor: ew-resize;
    position: absolute;
    right: -#{$spacer-width-px * 2}px;
    top: 0;
    z-index: 1;
    border-right: 2px solid $light-grey;
  }

  &--vertical &__spacer {
    width: 100%;
    height: #{$spacer-width-px}px;
  }

  &--vertical &__resizer {
    width: 100%;
    height: #{$spacer-width-px}px;
    cursor: ns-resize;
    bottom: -#{$spacer-width-px * 2}px;
    top: unset;
    right: unset;
    left: 0;
    border-right: unset;
    border-bottom: 2px solid $light-grey;

    > svg {
      transform: rotate(90deg);
    }
  }
}
