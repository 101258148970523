@import 'vars';

.task {
  &__content {
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    height: 60px;
    border-radius: 6px;
    padding: 0 15px;
    flex-direction: row;
    user-select: none;
    cursor: pointer;
    border: 1px solid $mid-grey;
    transition: top 0.3s ease-in-out;
    background: $white;
    z-index: 1;

    &:not(&--selected):hover {
      background: $light-grey;
    }

    &--selected {
      cursor: default;
      border: 2px solid $blue;
    }

    &--incoming {
      cursor: default;
    }

    > svg {
      font-size: 20px;
      width: 25px;
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &__text {
      flex: 1;
      overflow: hidden;
      text-align: left;
      margin-left: 10px;

      > p {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-of-type {
          font-size: 15px;
          font-weight: 500;
          line-height: 15px;
          margin-bottom: 5px;
        }

        &:last-of-type {
          line-height: 12px;

          > span {
            line-height: 12px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
}