@import 'vars';

.tab {
  align-items: center;
  background: $white;
  border: 0;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  outline-width: 0;

  &:hover:not(&--selected) &__text {
    color: $dark-grey;
  }

  &--selected {
    cursor: default;
  }

  &__wrapper {
    display: flex;
    text-align: center;
  }

  &__text {
    color: $mid-grey;
    margin-bottom: 5px;

    &--selected {
      color: $dark-blue;
      font-weight: $font-weight-bold;
    }

    &__loader {
      width: 0 !important;
      transition: width 0.2s ease-in-out;

      &--visible {
        width: 20px !important;
      }
    }
  }
}
