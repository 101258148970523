@import 'vars';
@import 'mixins';

.task-overlay-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background: $dark-blue;

  &__header {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    > span {
      font-size: 15px;
    }

    &__text {
      min-width: 0;
      margin-right: 20px;
      margin-left: 20px;

      &__connection {
        display: flex;
        align-items: baseline;

        &--softphone {
          margin-top: 8px;
        }

        > span {
          font-size: 12px;
          color: $white;
        }

        > span:first-of-type {
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > span:last-of-type {
          min-width: 50px;
          margin-left: 5px;
        }
      }

      > h2 {
        font-size: 15px;
        margin-bottom: 0;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $white;
      }

      &__time {
        width: 33px;
        display: inline-block;
      }

      &__queue {
        margin-top: 4px;

        &--softphone {
          margin-left: 8px;
        }

        > span {
          font-size: 12px;
          color: $dark-grey !important;
        }

        > span:last-of-type {
          margin-left: 5px;
        }
      }
    }

    &--closed {
      border-radius: 8px;
      transition: border-radius 0.25s ease-in-out;
    }
  
    &--open {
      border-radius: 8px 8px 0 0;
      transition: border-radius 0.25s ease-in-out;
    }
  }

  hr {
    margin: 15px 20px;
    border-top: 1px solid $white;
    top: 0;
  }

  &__actions {
    pointer-events: all;
    display: flex;
    align-items: center;

    > *:first-child:not(:last-child) {
      margin-right: 15px;
    }

    &--inline {
      margin-left: auto;
    }
  }

  &__actions-row {
    pointer-events: all;
    display: flex;
    align-items: center;

    > *:first-child:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__attributes {
    @include scrollable-container;
    overflow-y: auto !important; //override collapse overflow: hidden
    // margin-top: -1px; // Collapse shows a weird white border sometimes
    background: $dark-blue;

    &--open {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
