@import 'mixins';

.softphone-landing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  flex: 1;

  > svg {
    opacity: 0.5;

    @include small-softphone-only {
      font-size: 40px !important;
      height: 40px !important;
      width: 40px !important;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    
    > button {
      min-width: 200px;

      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
}