@import 'mixins';
@import 'vars';

.reactour {
  &__content-wrapper {
    margin-top: -20px; // As react-tour close icon is in a separate div, content-wrapper div needs to have a negative margin
  }

  &__popover {
    max-width: 500px !important;
    border-radius: 12px;
    padding: 40px !important;

    @include softphone-only {
      max-width: 315px !important;
      padding: 10px !important;
    }
  }

  &__paragraph {
    margin-top: 32px;

    &--additional {
      @include softphone-only {
        margin-top: 15px;
      }
    }

    @include softphone-only {
      margin-top: 15px;
    }
  }

  &__navigation-button-container {
    padding-top: 36px;
    display: flex;
    justify-content: flex-end;

    .navigation-button {
      width: 120px;

      &--first {
        width: 120px;
        margin-right: 20px;
      }
    }
  }
}
