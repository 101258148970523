@import 'vars';
@import 'mixins';

.chat {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  justify-content: space-between;

  &__box-container {
    overflow: auto;
  }

  &__queue-name-and-timer {
    margin-bottom: 10px;
  }

  &__container {
    @include scrollable-container;
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  &__connection-info {
    margin-bottom: auto;
    margin-top: -20px;
  }

  &__send {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    > .button {
      margin-left: 20px;
    }
  }

  &__start {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    text-align: center;
    margin-top: auto;

    > p {
      margin-top: 10px;
    }
  }

  &__messages {
    &__block {
      &__date {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        > span {
          height: 1px;
          width: 100%;
          background: $mid-grey;
        }

        > p {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}
