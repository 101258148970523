@import 'vars';
@import 'mixins';

.landing {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 50px;

  @media only screen and (max-width: 1200px) {
    padding: 30px;
  }

  @include softphone-only {
    padding: 0;
    box-shadow: unset;
  }

  > img {
    width: 100%;
    min-width: 0;
    max-width: 700px;
    margin-left: 50px;

    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }

  &__left-column {
    min-width: 50%;

    @media only screen and (max-width: 1150px) {
      min-width: 40%;
    }

    @media only screen and (max-width: 1000px) {
      width: 100%;
      justify-content: center;
      text-align: center;
    }

    @include softphone-only {
      text-align: left;
    }

    > h1 {
      font-size: 40px;

      @media only screen and (max-width: 1150px) {
        font-size: 30px;
      }

      @include softphone-only {
        font-size: 20px;
      }
    }

    > h2 {
      margin-top: 10px;
    }

    &__logos {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      @media only screen and (max-width: 1000px) {
        justify-content: center;
      }

      @include softphone-only {
        justify-content: unset;
      }

      > svg,
      img {
        width: auto;
      }

      > img {
        height: 35px;
      }

      > svg {
        height: 25px;
      }

      &__heart {
        margin-left: 15px;
        margin-right: 15px;
        color: $danger;
      }
    }

    &__footer {
      @media only screen and (max-width: 1000px) {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: center;

        > a {
          margin-right: 0;
          margin-left: 20px;
        }
      }

      @include softphone-only {
        justify-content: start;
      }

      > a {
        margin-right: 20px;
        font-size: 15px;
      }
    }

    &__checklist {
      margin-top: 50px;
      margin-bottom: 50px;

      @media only screen and (max-width: 1000px) {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
      }

      @include softphone-only {
        margin-left: unset;
      }

      &__item {
        display: flex;
        align-items: center;

        > svg {
          margin-right: 20px;
          font-size: 18px;
          color: $blue;
        }

        > span {
          font-size: 15px;
          color: $text;
          text-align: left;
        }

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
