@import 'vars';
@import 'mixins';

// Have to use a bunch of important here to use scss to override MUI styles
.button {
  height: 45px;
  min-height: 45px;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 15px !important;
  transition: none !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  white-space: nowrap;

  @include softphone-only {
    height: 35px;
    min-height: 35px;
    border-radius: 4px !important;
  }

  &__icon {
    margin-right: 10px;
  }

  &--busy &__icon,
  &--icon-only &__icon {
    margin-right: 0;
  }

  &--busy &__content {
    visibility: hidden;
  }

  &--busy &__icon {
    position: absolute;
  }

  &--icon-only {
    width: 45px !important;
    min-width: 45px !important;

    @include softphone-only {
      padding: 0 !important;
      height: 35px !important;
      min-height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;

      .button {
        &__icon {
          width: 17px !important;
          height: 17px !important;
        }
      }
    }
  }

  &--icon-only.button--small {
    height: 40px !important;
    min-height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;

    @include softphone-only {
      padding: 0 !important;
      height: 30px !important;
      min-height: 30px !important;
      width: 30px !important;
      min-width: 30px !important;
    }
  }

  &--round {
    border-radius: 50% !important;
    padding: 0 !important;
    height: 40px !important;
    min-height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;

    @include softphone-only {
      height: 30px !important;
      min-height: 30px !important;
      width: 30px !important;
      min-width: 30px !important;

      .button {
        &__icon {
          width: 15px !important;
          height: 15px !important;
        }
      }
    }
  }

  &--round.button--large {
    @include softphone-only {
      .button {
        &__icon {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }

  &--small {
    height: 40px;
    min-height: 40px;
    font-size: 12px;
  }

  &--thin {
    height: 30px;
    min-height: 30px;
    font-size: 12px;
  }

  &--round.button--small {
    height: 30px !important;
    min-height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
  }

  &--large {
    height: 50px !important;
    width: 50px !important;
    min-height: 50px !important;
    min-width: 50px !important;
    font-size: 15px;

    @include softphone-only {
      height: 40px !important;
      min-height: 40px !important;
      width: 40px !important;
      min-width: 40px !important;
    }
  }

  &--primary {
    background: $primary !important;
    color: $white !important;

    &:hover {
      background: lighten($primary, 5%) !important;
    }
  }

  &--secondary {
    border: 2px solid $primary !important;
    background: $white !important;
    color: $primary !important;

    &:hover {
      background: $primary !important;
      color: $white !important;

      svg {
        color: $white;
      }
    }
  }

  &--danger {
    background: $danger !important;
    color: $white !important;

    &:hover {
      background: lighten($danger, 5%) !important;
    }
  }

  &--neutral {
    background: $mid-grey !important;
    color: $text !important;

    &:hover {
      background: lighten($mid-grey, 2%) !important;
    }
  }

  &--success {
    background: $success !important;
    color: $white !important;

    &:hover {
      background: lighten($success, 5%) !important;
    }
  }

  &--warning {
    background: $warning !important;
    color: $white !important;

    &:hover {
      background: lighten($warning, 5%) !important;
    }
  }

  &--disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
