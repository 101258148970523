@import 'vars';
@import 'mixins';

$login-medium: 610px;
$login-small: 410px;
$login-tiny: 350px;

.login {
  display: flex;
  box-sizing: border-box;

  > .loader {
    margin: auto;
  }

  &__content {
    display: flex;
    flex: 1;
    box-sizing: border-box;
    height: 100vh;
    padding-left: 10%;
    padding-right: 10%;
    max-width: 2000px;
    margin: auto;

    @include softphone-only {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__left {
      width: 30%;
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 2%;

      &__extended-load {
        background: $light-grey;
        border-radius: 8px;
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        opacity: 0;
        min-height: 0;
        height: 0;
        transition: all 300ms ease-in-out;
        padding-left: 20px;
        padding-right: 20px;
        pointer-events: none;

        @media only screen and (max-width: $login-medium) {
          padding-left: 15px;
          padding-right: 15px;
        }

        @media only screen and (max-width: $login-small) {
          padding-left: 10px;
          padding-right: 10px;
        }

        &-enter-active,
        &-enter-done {
          min-height: 90px;
          opacity: 1;
          margin-bottom: 50px;
          pointer-events: all;

          @media only screen and (max-width: $login-medium) {
            min-height: 110px;
            margin-bottom: 30px;
          }
        }

        &__text {
          > p {
            @media only screen and (max-width: $login-small) {
              font-size: $font-size-small;
            }
          }

          > p:last-of-type {
            margin-top: 10px;
            font-size: $font-size-small;

            > a {
              font-size: $font-size-small;
            }

            @media only screen and (max-width: $login-small) {
              font-size: $font-size-extra-small;

              > a {
                font-size: $font-size-extra-small;
              }
            }
          }
        }

        > svg {
          margin-right: 20px;

          @media only screen and (max-width: $login-medium) {
            margin-right: 15px;
          }

          @media only screen and (max-width: $login-small) {
            font-size: 20px !important;
            width: 20px !important;
            height: 20px !important;
            margin-right: 10px;
          }
        }
      }

      @media only screen and (max-width: 1000px) {
        margin-right: 0;
      }

      > svg {
        width: 150px;

        @include softphone-only {
          width: 100px;
        }
      }

      > h1 {
        margin-top: 40px;
      }

      > h2 {
        margin-bottom: 50px;

        @media only screen and (max-width: $login-medium) {
          margin-bottom: 30px;
        }
      }

      > p {
        margin-bottom: 50px;

        @media only screen and (max-width: $login-medium) {
          margin-bottom: 30px;
        }
      }

      > a {
        text-decoration: underline;
        width: fit-content;

        &:first-of-type {
          margin-bottom: 20px;
        }
      }
    }

    &__right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2%;

      > svg {
        max-width: 600px;
      }

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
}
