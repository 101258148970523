@import 'vars';

.clickable-icon {
  border: 0;
  background: none;
  padding: 0;
  color: $dark-blue;
  cursor: pointer;

  &--with-hover {
    > svg {
      transition: transform 0.2s ease-in-out;
      will-change: contents;
    }

    &:hover {
      > svg {
        transform: scale(1.1);
      }
    }
  }
}
