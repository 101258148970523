@import 'vars';

.wallboard {
  .MuiGrid-item {
    flex: 1;
  }

  .component-title {
    > button {
      width: 22px !important;
      min-width: 22px !important;
      min-height: 22px !important;
      height: 22px !important;
    }
  }

  &__stats-title {
    margin-left: 40px !important;
    font-size: 16px;
    padding-top: 4px;

    &__queue-select {
      padding-top: 4px;

      > button {
        width: 45px;
      }
    }
  }

  &--maximised &__stats-title {
    padding-top: 2px;
  }

  &__stat-item {
    color: #3a404e;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    position: relative;
    height: 100%;
    min-height: 250px !important;

    .MuiButtonBase-root {
      padding: 0;
    }

    > canvas {
      max-height: 150px !important;
    }

    > p {
      font-size: 18px;
      margin-bottom: 20px;
    }

    > h1 {
      align-self: center;
      justify-self: center;
      line-height: 50px;
      color: $dark-blue;
      font-size: 60px;
      margin-top: auto;
      margin-bottom: auto;
    }

    &--doughnut {
      > h1 {
        position: absolute;
        bottom: 35px;
        font-size: 40px;
      }
    }
  }
}
